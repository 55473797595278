#home-container {
  max-width: 840px;
  margin-left: 25%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-size: cover;
  color: #fff;
  padding-left: 110px;
  padding-top: 130px;
}

#home-container #home-header {
  font-size: 4em;
  font-weight: 500;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

#home-container #home-subheader {
  color: #fff;
  font-size: 3em;
  font-weight: 300;
  text-transform: none;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  margin: 0;
}

#home-container a {
  text-decoration: solid;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  #home-container {
    max-width: 100%;
    margin: 20px;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-size: cover;
    color: #fff;
    padding-left: 0px;
    padding-top: 0px;
  }
}
