#main-container {
  flex: 5;
  overflow: auto;
  background-color: #855a5c;
}

#main-container #footer {
  display: none;
}

@media only screen and (max-width: 768px) {
  #main-container #footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: white;
  }
}
