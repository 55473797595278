.app-container {
  display: flex;
  height: 100vh;
}

@media only screen and (max-width: 768px) {
  .app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
}
