#footer-container {
  flex-direction: column;
  text-align: center;
  margin: 20px;
  font-size: 12px;
}

#footer-container #social-icons > a {
  background: 0 0;
  font-size: 28px;
  margin: 5px;
  color: #fff;
}
