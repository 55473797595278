#sidebar-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: rgba(145, 13, 13, 0.8);
  min-width: 200px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
}

#sidebar-container #name {
  font-size: 22px;
  font-weight: 500;
  margin: 0;
  margin-top: 100px;
  text-align: center;
}

#sidebar-container #title {
  font-size: 16;
  font-weight: 300;
  margin: 0;
  margin-bottom: 100px;
  text-align: center;
}

#sidebar-container #side-nav {
  margin-left: 20px;
  margin-right: 20px;
}

#sidebar-container .tab-title {
  text-align: center;
  font-size: 16px;
}

#sidebar-container #sidebar a {
  text-decoration: solid;
  color: #fff;
}

#sidebar-container #footer {
  justify-content: flex-end;
  margin-top: auto;
}

@media only screen and (max-width: 768px) {
  #sidebar-container #sidebar {
    width: 100%;
  }

  #sidebar-container #name {
    margin-top: 20px;
  }

  #sidebar-container #title {
    margin-bottom: 20px;
  }

  #sidebar-container #sidebar-routes {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  #sidebar-container #footer {
    display: none;
  }
}
